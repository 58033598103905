import {marketIdSchema} from '@smart/types';
import {jsonSafeParse} from '@smart/utils';
import type {PropsWithChildren} from 'react';
import React from 'react';
import type {AemContent} from '../lambda/aem/types';
import type {BooleanToggle} from '../utils/api/fetch-aem';
import {fetchAem} from '../utils/api/fetch-aem';
import {sanitizeLogs} from '../utils/sanitize-logs';
import {MarketConfigContext} from './MarketConfigContext';

export const AemContentContext = React.createContext<AemContent>(
  {} as AemContent,
);

export const AemContentProvider = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const [aemContent, setAemContent] = React.useState<AemContent>();

  const marketConfig = React.useContext(MarketConfigContext);

  React.useEffect(() => {
    const abortController = new AbortController();

    const marketId = marketIdSchema.safeParse(
      marketConfig.market.toLowerCase(),
    );

    if (!marketId.success) {
      throw Error(`Invalid marketId: ${marketConfig.market}`);
    }

    /**
     * NOTE: id.smart doesn't have preview support on infrastructure level
     * this is a tweak to allow fetching non-published AEM content
     * 'preview' should be set in localStorage with 'true' || 'false' value, if not set -> 'false' is used
     **/
    const preview = jsonSafeParse(
      localStorage.getItem(`preview`) || `false`,
    ) as BooleanToggle;

    const mockResponseEnabled = jsonSafeParse(
      localStorage.getItem(`mock-aem-bff`) || `true`,
    ) as BooleanToggle;

    fetchAem({
      marketId: marketId.data,
      mockResponseEnabled,
      preview,
      signal: abortController.signal,
    })
      .then((data) => {
        setAemContent(data);
      })
      .catch((e) => {
        if (!abortController.signal.aborted) {
          if (process.env.ENV_NAME !== `prod`) {
            console.error({
              errorType: `AEM_FETCH_ERROR`,
              message: sanitizeLogs(e.message),
            });
          }
        }
      });

    return () => abortController.abort();
  }, []);

  return aemContent ? (
    <AemContentContext.Provider value={aemContent}>
      {children}
    </AemContentContext.Provider>
  ) : (
    <></>
  );
};
